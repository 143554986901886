import { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import {
  LinksFunction,
  MetaFunction,
  LoaderFunction,
  useLoaderData,
} from "remix";
import {
  Meta,
  Links,
  Scripts,
  LiveReload,
  useCatch,
  ScrollRestoration,
} from "remix";
import { Outlet } from "react-router-dom";
import { TabBar, Header, Footer } from "../app/src/components/display";
import { useScrollRestoration } from "./src/hooks";
import throttle from "lodash.throttle";
// @ts-ignore
import tailwindCSS from "./styles/tailwind.css";
// @ts-ignore
import globalStyles from "./styles/global.css";

import { loadPageImages } from "~/src/utils/remix-image";
import { getDomainUrl, removeTrailingSlash } from "./src/utils/misc";

export let links: LinksFunction = () => {
  return [
    {
      rel: "preload",
      as: "font",
      href: "/fonts/effra-400.woff2",
      type: "font/woff2",
      crossOrigin: "anonymous",
    },
    {
      rel: "preload",
      as: "font",
      href: "/fonts/effra-500.woff2",
      type: "font/woff2",
      crossOrigin: "anonymous",
    },
    {
      rel: "preload",
      as: "font",
      href: "/fonts/effra-700.woff2",
      type: "font/woff2",
      crossOrigin: "anonymous",
    },
    {
      rel: "preload",
      as: "font",
      href: "/fonts/proxima-nova-500.woff2",
      type: "font/woff2",
      crossOrigin: "anonymous",
    },
    {
      rel: "preload",
      as: "font",
      href: "/fonts/proxima-nova-800.woff2",
      type: "font/woff2",
      crossOrigin: "anonymous",
    },
    { rel: "stylesheet", href: tailwindCSS },
    { rel: "stylesheet", href: globalStyles },
    { rel: "stylesheet", href: TabBar.styles },
    { rel: "stylesheet", href: Footer.styles },
    ...Header.links,
  ];
};

export const meta: MetaFunction = () => ({
  viewport: "width=device-width, initial-scale=1, viewport-fit=cover",
});

export const loader: LoaderFunction = async ({ request }) => {
  let requestInfo = {
    origin: getDomainUrl(request),
    path: new URL(request.url).pathname,
  };
  console.log({ requestInfo });
  return {
    requestInfo,
  };
};

function Document({
  children,
  title,
}: {
  children: React.ReactNode;
  title?: string;
}) {
  let location = useLocation();

  const data = useLoaderData();

  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  let previousScrollValue: number | undefined = useRef(undefined).current;
  // let isHeaderSolidBackground = useRef(true).current

  const HEADER_HIDE_THRESHOLD = 440;

  useEffect(() => {
    function handleScroll() {
      let currentScroll = window.scrollY;

      if (currentScroll < HEADER_HIDE_THRESHOLD) {
        setIsHeaderVisible(true);
      }

      if (typeof previousScrollValue !== "number") {
        previousScrollValue = currentScroll;
        return;
      }

      const direction = currentScroll > previousScrollValue ? "down" : "up";

      if (
        // isHeaderVisible &&
        direction === "down" &&
        currentScroll > previousScrollValue + 20 &&
        currentScroll > HEADER_HIDE_THRESHOLD
      ) {
        setIsHeaderVisible(false);
      } else if (
        // !isHeaderVisible &&
        direction === "up"
      ) {
        if (
          window.innerWidth < 1100 &&
          currentScroll + 20 < previousScrollValue
        ) {
          setIsHeaderVisible(true);
        }

        if (
          window.innerWidth >= 1100 &&
          currentScroll < HEADER_HIDE_THRESHOLD &&
          currentScroll + 20 < previousScrollValue
        ) {
          setIsHeaderVisible(true);
        }
      }

      previousScrollValue = currentScroll;
    }

    const throttledScrollHandler = throttle(handleScroll, 250);

    window.addEventListener("scroll", throttledScrollHandler);

    return () => {
      window.removeEventListener("scroll", throttledScrollHandler);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      loadPageImages();
    }
  }, [location]);

  // useScrollRestoration();

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <link rel="icon" href="/favicon.png" type="image/png" />
        <link
          rel="canonical"
          href={removeTrailingSlash(
            `${data.requestInfo.origin}${data.requestInfo.path}`
          )}
        />
        {title ? <title>{title}</title> : null}
        <Meta />
        <Links />
      </head>
      <body>
        <div id="__remix">
          <Header isHeaderVisible={isHeaderVisible} />
          <TabBar />
          <div className="min-h-screen">{children}</div>
          <span className="tab-bar-spacer" />
          <span className="block h-10"></span>
          <Footer />
        </div>
        <script src="/utils/balance-text.js" />
        <script
          dangerouslySetInnerHTML={{
            __html: `balanceText()`,
          }}
        />
        <script
          src="https://cdn.jsdelivr.net/npm/react@16.8.4/umd/react.production.min.js"
          integrity="sha256-ctUamuIgSCQg1wsh8Iw0QbDXScmyXhjJ6lxYUscC3FA="
          crossOrigin="anonymous"
          async
          defer
        ></script>
        {/* <script
        src="https://cdn.jsdelivr.net/npm/react-dom@16.8.4/umd/react-dom.production.min.js"
        integrity="sha256-8uWWG/7CB3OS89Cw67+B++t6w0EMGfQE9C6OGps+Wd8="
        crossOrigin="anonymous"
        ></script>
        <script
        src="https://cdn.jsdelivr.net/npm/algoliasearch@4.5.1/dist/algoliasearch-lite.umd.js"
        integrity="sha256-EXPXz4W6pQgfYY3yTpnDa3OH8/EPn16ciVsPQ/ypsjk="
        crossOrigin="anonymous"
      ></script> */}
        <script
          src="https://cdn.jsdelivr.net/npm/react-instantsearch-dom@6.7.0/dist/umd/ReactInstantSearchDOM.min.js"
          integrity="sha256-wggJgvcPaPOJnfujGmGMq7LzJgc7/EqEtLCW/BPZy7E="
          crossOrigin="anonymous"
          async
          defer
        ></script>
        {process.env.NODE_ENV === "development" && <LiveReload />}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export default function App() {
  return (
    <Document>
      <Outlet />
    </Document>
  );
}

export function CatchBoundary() {
  let caught = useCatch();

  switch (caught.status) {
    case 401:
    case 404:
      return (
        <Document title={`${caught.status} ${caught.statusText}`}>
          <h1>
            {caught.status} {caught.statusText}
          </h1>
        </Document>
      );

    default:
      throw new Error(
        `Unexpected caught response with status: ${caught.status}`
      );
  }
}

export function ErrorBoundary({ error }: { error: Error }) {
  console.error(error);

  return (
    <Document title="Uh-oh!">
      <h1>App Error</h1>
      <pre>{error.message}</pre>
      <p>
        Replace this UI with what you want users to see when your app throws
        uncaught errors.
      </p>
    </Document>
  );
}
